import React from 'react';
import ReactDOM from 'react-dom/client';
import { Web3ReactProvider } from "@web3-react/core";
import App from './App';
import Bridge from './components//Bridge/Bridge';
import MultisigVault from './components/Multisig/MultisigVault';
import CreateSign from './components/SignUp/CreateSign';
import SubmitTransaction from './components/Multisig/SubmitTransaction';
import Login from './components/SignUp/Login';
import ResetPassword from './components/SignUp/ResetPassword';
import SignUp from './components/SignUp/SignUp';
import Account from './components/Multisig/AccountProfil';
import AccountWallets from './components/Multisig/AccountWallets';
import AccountSecurity from './components/Multisig/AccountSecurity';
import AccountNotifications from './components/Multisig/AccountNotifications';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import reportWebVitals from './reportWebVitals';
import store from './store'
import { Provider } from 'react-redux'
import { ethers } from 'ethers';

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <Router basename={process.env.PUBLIC_URL}>
              <Routes>
                {/* <Route path="/" element={<App/>}></Route> */}
                <Route path="/"  element={<Bridge/>}></Route>
                {/* <Route path="/MultisigVault"  element={<MultisigVault/>}></Route> */}
                {/* <Route path="/CreateSign"  element={<CreateSign/>}></Route> */}
                {/* <Route path="/SubmitTransaction"  element={<SubmitTransaction/>}></Route> */}
                {/* <Route path="/Login"  element={<Login/>}></Route> */}
                {/* <Route path="/ResetPassword"  element={<ResetPassword/>}></Route> */}
                {/* <Route path="/SignUp"  element={<SignUp/>}></Route> */}
                {/* <Route path="/Account"  element={<Account/>}></Route> */}
                {/* <Route path="/AccountWallets"  element={<AccountWallets/>}></Route> */}
                {/* <Route path="/AccountSecurity"  element={<AccountSecurity/>}></Route> */}
                {/* <Route path="/AccountNotifications"  element={<AccountNotifications/>}></Route> */}
            </Routes>
          </Router>
        </Provider>
      </Web3ReactProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
