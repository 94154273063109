import "../App.scss";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import NftEx from "../media/NftEx1.png";
import NftEx2 from "../media/NftEx2.png";

function NftCard() {
  return (
    <Card className="NftCard m-2 p-3">
      <Card.Img className="img-fluid" src={NftEx} />
      <Card.Body>
        <Stack direction="horizontal" gap={2}>
          <div className="avatar">
            <img
              src={NftEx2}
              className="rounded-circle img-fluid"
              alt="Avatar"
            />
          </div>
          <div className="ms-auto">
            <h5 className="h5-responsive bold">Collection name</h5>
          </div>
        </Stack>
      </Card.Body>

      <Card.Footer className="pb-0">
        <Stack direction="horizontal" gap={2}>
          <div className="ms-auto pe-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 12.09 13.94"
              height="25"
            >
              <path
                fill="currentcolor"
                d="M6,0,0,3.5v7l6.05,3.49,6-3.49V3.5Z"
              />
            </svg>
          </div>
          <div>
            <h5 className="pt-2 h5-responsive bold">12 NFTs</h5>
          </div>
        </Stack>
      </Card.Footer>
    </Card>
  );
}

export default NftCard;
