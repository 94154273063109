import React, { Component } from "react";
import Slider from "react-slick";
import NftCard from "./NftCard";
import Button from "react-bootstrap/Button";
import fleche from "../media/arrowW.svg";
import flecheL from "../media/arrowLW.svg";

export default class PreviousNextMethods extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      maxHeight: 450,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          <NftCard />
          <NftCard />
          <NftCard />
          <NftCard />
          <NftCard />
          <NftCard />
          <NftCard />
          <NftCard />
        </Slider>
        <div className="switch  pt-5 text-center">
          <Button variant="outline-light">
            <img
              src={flecheL}
              height="20px"
              alt="previous"
              onClick={this.previous}
            />
          </Button>
          <Button variant="outline-light">
            <img src={fleche} height="20px" alt="next" onClick={this.next} />
          </Button>
        </div>
      </div>
    );
  }
}
