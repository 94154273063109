import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import {ethers, BigNumber} from "ethers";

import axios from 'axios';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from 'react-bootstrap/Alert';

// importing aos
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "../../utils/web3";

import metamask_Logo from "../../assets/svg/metamask_Logo.svg";
import walletconnect_Logo from "../../assets/svg/walletconnect_Logo.svg";
import coinbase_Logo from "../../assets/images/coinbase_Logo.png";

import "../../App.scss";
import ConnectButton from "./ConnectButton";
import CardBridge1 from "./cardBridge1";
import CardBridge2 from "./CardBridge2";
import {BA, TKNB, TKNE, TKNA, TKNP} from "../../constants/addresses";
import tokenAbi from "../../abis/Token.json";
import { setProgress } from "../../futures/bridge/bridgeSlice";

import { ethChainId, bscChainId, avaxChainId, pZkevmChainId, ethChainIdDec } from "../../utils/web3";

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "7px",
    backgroundColor: "black",
    cursor: "pointer"
  },
  button: {
    height: "40px",
    padding: "0 20px",
    textAlign: "center",
    fontWeight: "600",
    letterSpacing: "0.2px",
    fontSize: "15px",
    margin: "20px 20px",
    border: "none",
    background: "black",
    color: "white"
  },
  text: {
    color: "white"
  },
  modalTitle: {
    marginBottom: "20px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "700",
    fontSize: "20px"
  }
}


function HeaderBridge(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  const dispatch = useDispatch()

  const { account, activate, deactivate, chainId, library } = useWeb3React();
  
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [controllerInfo, setControllerInfo] = useState(null);
  const [token, setToken] = useState();
  const [tokenAddress, setTokenAddress] = useState(null);
  const [bridgeAddress, setBridgeAddress] = useState(null);
  const [balance, setBalance] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    loadControllerInfo(props.networkFrom, props.networkTo);
  }, [props.networkFrom, props.networkTo]);

  useEffect(() => {
    setBridgeAddress(BA);
    // eslint-disable-next-line default-case
    switch(chainId) {
      case 1:
        setTokenAddress(TKNE);
        break;
      case 56:
        setTokenAddress(TKNB);
        break;
      case 43114:
        setTokenAddress(TKNA);
        break;
      case 1101:
        setTokenAddress(TKNP);
        break;
    }
  }, [chainId])

  useEffect(() => {
    const initContract = async () => {
      const _token = new ethers.Contract(tokenAddress, tokenAbi.abi, library.getSigner(account));
      setToken(_token);
      const _balance = await _token.balanceOf(account);
      console.log(_balance.toString())
      setBalance(_balance);
    };

    if(tokenAddress && account && library) initContract();
  }, [library, account, tokenAddress, props.networkFrom]);

  useEffect(() => {
    if(account) {
      let chainId;
      // eslint-disable-next-line default-case
      switch(props.networkFrom.key) {
        case "E":
          chainId = ethChainId;
          break;
        case "B":
          chainId = bscChainId;
          break;
        case "A":
          chainId = avaxChainId;
          break;
        case "P":
          chainId = pZkevmChainId;
          break;
      };
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }],
      });
    }
  }, [props.networkFrom, account]);

  const loadControllerInfo = (networkFrom, networkTo) => {
    console.log("load controller info")
    const direction = networkFrom.key + networkTo.key;
    axios.get(`https://tozexbridge.uc.r.appspot.com/info?direction=${direction}`)
      .then(response => setControllerInfo(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }

  const onConnect = (type, network) => {
    connect(activate, type, network)
      .then((res) => {
        window.localStorage.setItem("connectorId", network);
        setIsAuthModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setIsAuthModalOpen(false);
      });
  };

  const onDisconnect = () => {
    localStorage.clear();
    deactivate();
  };

  const handleSwap = async () => {
    try {
      dispatch(setProgress(true));
      const direction = props.networkFrom.key + props.networkTo.key;

      // Check if the user has already approved the token for the transfer amount
      const amount = BigNumber.from(props.amount).mul(BigNumber.from('10').pow('18'));

      const allowance = await token.allowance(account, bridgeAddress);
      
      if (allowance.lt(amount)) {
        // Approve the token
        const tx = await token.approve(bridgeAddress, amount);
        await tx.wait();
      }

      axios.get(`https://tozexbridge.uc.r.appspot.com/process?direction=${direction}&address=${account}&token_index=0`)
      .then(res =>{ console.log(res); dispatch(setProgress(false)); })
      .catch(error => {
        console.log({error})
        setErrorMsg(error.response.data);
        dispatch(setProgress(false));
        setTimeout(function() {
          setErrorMsg("");
        }, 5000);
      })
      // Show a success message
    } catch (error) {
      console.log(error);
      dispatch(setProgress(false));
    }
    loadControllerInfo(props.networkFrom, props.networkTo);
  };

  return (
    <div>
      <header className="bg-bridge text-center  Sect Sect-Header flex-column d-flex justify-content-center">
        
        <Container>
          <div className="text-center my-3">
            <svg height="100" viewBox="0 0 620.29 256.42">
              <g>
                <line
                  x1="395.28"
                  y1="259.84"
                  x2="617.44"
                  y2="-2.09"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="5"
                />
              </g>
            </svg>
            <h1
              data-aos="zoom-in-right"
              data-aos-duration="1200"c
              data-aos-delay="0"
              data-aos-easing="ease-in-out"
              className="text-white py-3 display-4 h1-responsive text-uppercase"
            >
              <span className="bold">Bridge</span>
            </h1>
            {errorMsg !== "" ? 
              (
                <Alert variant="danger">
                  {errorMsg}
                </Alert>
              ) : (
                <></>
              )
            } 
          </div>

          <Row className="pb-4">
            
            <Col
              xs={12}
              lg={7}
              className="my-3 TozBridge d-flex flex-column justify-content-center align-items-center mx-auto"
            >
              <div className="">
                <h4 className="text-white h4-responsive">
                  Bridge your universal tokens issued on Tozex with security at
                  a low cost on the blockchain network of your choice.
                </h4>
              </div>
            </Col>
            <Col xs={12} lg={5} className="mx-auto">
              <CardBridge1 
                network={props.networkFrom} 
                setNetwork={props.setNetworkFrom} 
                networkTo={props.networkTo}
                balance={balance}
                controllerInfo={controllerInfo} 
                {...props}
              />
              <div className="my-3 text-center ">
                <Button variant="bridge" onClick={() => props.swapNetwork()}>
                  <svg
                    className="display-block ms-n2"
                    viewBox="0 0 97.25 60"
                    height="25"
                  >
                    <path
                      fill="currentcolor"
                      d="M50.63,26.19l3.64,3.39L68.34,14.47,68.19,51.7l4.88,0,0-37.23L87,29.58l3.6-3.34-20-21.51Z"
                    />
                    <path
                      fill="currentcolor"
                      d="M48.65,35,45,31.65,30.93,46.75l.15-37.23H26.2V46.74L12.26,31.65,8.67,35l20,21.5Z"
                    />
                  </svg>
                </Button>
              </div>
              <CardBridge2 
                network={props.networkTo} 
                setNetwork={props.setNetworkTo} 
                {...props}
              />
              <div className="pt-3   text-center">
              <>
                {account === undefined ? (
                  <div>
                    <Button 
                      shape="round" 
                      type="primary" 
                      style={styles.button} 
                      onClick={() => setIsAuthModalOpen(true)}
                    >
                      Connect Wallet
                    </Button>
                  </div>
                ) : (
                  <>
                    <Button
                      size="large"
                      type="primary"
                      style={styles.button}
                      onClick={() => handleSwap()}
                    >
                      Swap
                    </Button>
                  </>
                )}
              </>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal
          show={isAuthModalOpen}
          footer={null}
          onHide={() => setIsAuthModalOpen(false)}
          {...props}
          aria-labelledby="ConnectWallet"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="ConnectWallet">Connect wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column">
            <ConnectButton
              label="MetaMask"
              image={metamask_Logo}
              onClick={() => onConnect("injected", props.networkFrom.key)}
            /> 

            <ConnectButton
              label="WalletConnect"
              image={walletconnect_Logo}
              onClick={() => onConnect("walletConnect", props.networkFrom.key)}
            />

            <ConnectButton
              label="Coinbase Wallet"
              image={coinbase_Logo}
              onClick={() => onConnect("coinbase", props.networkFrom.key)}
            />
          </Modal.Body>
        </Modal>
      </header>
    </div>
  );
}

export default HeaderBridge;
