import detectEthereumProvider from '@metamask/detect-provider';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import BigNumber from 'bignumber.js';

import { URLS } from "../constants/networks";

export const ethChainId = process.env.REACT_APP_PUBLIC_ENV === 'production' ? '0x1' : '0xaa36a7';
export const bscChainId = process.env.REACT_APP_PUBLIC_ENV === 'production' ? '0x38' : '0x61';
export const avaxChainId = '0xa86a';
export const pZkevmChainId = '0x44d';

export const ethChainIdDec = process.env.REACT_APP_PUBLIC_ENV === 'production' ? 1 : 11155111;
export const bscChainIdDec = process.env.REACT_APP_PUBLIC_ENV === 'production' ? 56 : 97;
export const avaxChainIdDec = 43114;
export const pZkevmChainIdDec = 1101;

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 11155111, 42, 137, 80001, 56, 97, 43114, 1101],
});

export const CoinbaseWallet = new WalletLinkConnector({
  url: URLS[1][0],
  appName: 'Tozex',
});

export const WalletConnectProvider = new WalletConnectConnector({
  rpc: URLS,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const WalletConnectOptions = {
  injected: { name: 'Metamask', provider: injected },
  coinbase: { name: 'Coinbase', provider: CoinbaseWallet },
  walletConnect: { name: 'WalletConnect', provider: WalletConnectProvider },
};

export async function connect(activate, type, network) {
  try {
    let chainId;
    // eslint-disable-next-line default-case
    switch(network) {
      case "E":
        chainId = ethChainId;
        break;
      case "B":
        chainId = bscChainId;
        break;
      case "A":
        chainId = avaxChainId;
        break;
      case "P":
        chainId = pZkevmChainId;
        break;
    };
    const w = window;

    if (type === 'injected') {
      const provider = await detectEthereumProvider();
      if (!provider) {
        return false;
      }
    }

    await activate(WalletConnectOptions[type].provider);
    const connectedProvider =
      type === 'injected'
        ? w.ethereum
        : type === 'walletConnect'
        ? WalletConnectOptions[type].provider.walletConnectProvider
        : WalletConnectOptions[type].provider.provider;

    await connectedProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
    localStorage.setItem('connector_id', type);
    return true;
  } catch (ex) {
    throw new Error(ex.message);
  }
}

export const toDecimals = (value, decimals = 18) => {
  const TENPOW_ = new BigNumber(10).pow(decimals);
  return new BigNumber(value).div(TENPOW_).toFixed();
};

export const toNDecimals = (value, decimals) => {
  const TENPOW = new BigNumber(10).pow(decimals);
  return new BigNumber(value).multipliedBy(TENPOW).toFixed(0);
};
