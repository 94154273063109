import "../../App.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logosW from "../../media/logosW.svg";

function Cryptos() {
  return (
    <div>
      <Container fluid className="bg-bridge cryptos">
        <div className="FondLogos w-100">
          <Row className="pb-5 text-center d-flex flex-row align-items-center">
            <Col xs={11} lg={6} className="text-center mx-auto my-2">
              <img
                className="pt-4 img-fluid"
                src={logosW}
                alt="cryptos"
                width={600}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
export default Cryptos;
