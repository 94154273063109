export const NETWORKS = [
  { label: 'Ethereum Mainnet', svg: "#Etherum", value: 'ethereum', key: 'E' },
  { label: 'BNB Chain', svg: "#BNB", value: 'binance', key: 'B' },
  { label: 'Avalanche', svg: "#Avalanche", value: 'avalanche', key: 'A' },
  { label: 'Polygon Zkevm', svg: "#Polygon", value: 'polygon_zkevm', key: 'P' },
];



const ETH = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18
};

const MATIC = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18
};

const BSC = {
  name: "BNB Chain",
  symbol: "BNB",
  decimals: 18
};

const AVAX = {
  name: "Avalanche",
  symbol: "AVAX",
  decimals: 18
};

function isExtendedChainInformation(
  chainInformation
){
  return !!(chainInformation)?.nativeCurrency;
}

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId];
  console.log(chainInformation)
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls
    };
  } else {
    return chainId;
  }
}

export const getNativeByChain = (chainId) => {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) return chainInformation.nativeCurrency.symbol;
  return undefined;
};

export const getExplorer = (chainId) => {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) return chainInformation.blockExplorerUrls;
  return undefined;
};

export const CHAINS= {
  1: {
    urls: [
      process.env.REACT_APP_MAINNET_RPC,
      `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`,
      "https://cloudflare-eth.com"
    ].filter((url) => url !== undefined),
    name: "Mainnet",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://etherscan.io"]
  },
  11155111: {
    urls: [process.env.REACT_APP_SEPOLIA_RPC].filter((url) => url !== undefined),
    name: "Sepolia",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://goerli.infura.io/v3"]
  },
  // Polygon
  137: {
    urls: [
      `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      "https://polygon-rpc.com"
    ].filter((url) => url !== undefined),
    name: "Polygon Mainnet",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://polygonscan.com"]
  },
  80001: {
    urls: [`https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`].filter(
      (url) => url !== undefined
    ),
    name: "Polygon Mumbai",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://mumbai.polygonscan.com"]
  },
  // BSC
  56: {
    urls: [process.env.REACT_APP_BSC_MAINNET_RPC].filter((url) => url !== undefined),
    name: "BNB Chain",
    nativeCurrency: BSC,
    blockExplorerUrls: ["https://bscscan.com/"]
  },
  97: {
    urls: [process.env.REACT_APP_BSC_TESTNET_RPC].filter((url) => url !== undefined),
    name: "BNB Testnet",
    nativeCurrency: BSC,
    blockExplorerUrls: ["https://testnet.bscscan.com/"]
  },
  43114: {
    urls: [process.env.REACT_APP_AVAX_MAINNET_RPC].filter((url) => url !== undefined),
    name: "Avalanche",
    nativeCurrency: AVAX,
    blockExplorerUrls: ["https://snowtrace.io/"]
  },
  1101: {
    urls: [process.env.REACT_APP_PZKEVM_MAINNET_RPC].filter((url) => url !== undefined),
    name: "Polygon ZKEVM",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://zkevm.polygonscan.com/"]
  },
};

export const URLS = Object.keys(CHAINS).reduce(
  (accumulator, chainId) => {
    const validURLs = CHAINS[Number(chainId)].urls;

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs;
    }

    return accumulator;
  },
  {}
);
