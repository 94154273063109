import { useWeb3React } from "@web3-react/core";
import { Button, Alert } from "react-bootstrap";
import {ethers} from "ethers";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import "../../App.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";

import bridgeAbi from "../../abis/BridgeAssist.json";
import multisigAbi from "../../abis/Multisig.json";
import {BA, ZERO, MSE, MSB, MSA, MSP} from "../../constants/addresses";
import { CHAINS } from "../../constants/networks";
import { setProgress } from "../../futures/bridge/bridgeSlice";

import { ethChainId, bscChainId, avaxChainId, pZkevmChainId, ethChainIdDec, bscChainIdDec, avaxChainIdDec, pZkevmChainIdDec } from "../../utils/web3";

const styles = {
  button: {
    height: "40px",
    padding: "0 20px",
    textAlign: "center",
    fontWeight: "600",
    letterSpacing: "0.2px",
    fontSize: "15px",
    // margin: "20px 20px",
    border: "none",
    background: "white",
    color: "black"
  },
  disabledbutton: {
    height: "40px",
    padding: "0 20px",
    textAlign: "center",
    fontWeight: "600",
    letterSpacing: "0.2px",
    fontSize: "15px",
    // margin: "20px 20px",
    border: "none",
    background: "#858282",
    color: "black"
  }
}

const getTime = (timestamp) => {
  const date = new Date(timestamp);

  const month = date.getMonth() + 1; // add 1 to get 1-12 range
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

function ResponsiveExample() {
  const { account, library, chainId } = useWeb3React();
  const [txs, setTxs] = useState([]);
  const [bridge, setBridge] = useState();
  const [bridgeAddress, setBridgeAddress] = useState(null);
  const [msAddress, setMsAddress] = useState(null);
  const [multisig, setMultisig] = useState(null)
  const [isAdmin, setAdmin] = useState(false);
  const progress = useSelector((state) => state.bridge.progress)
  const dispatch = useDispatch()


  useEffect(() => {
    setBridgeAddress(BA);
    // eslint-disable-next-line default-case
    switch(chainId) {
      case 1:
        setMsAddress(MSE);
        break;
      case 56:
        setMsAddress(MSB);
        break;
      case 43114:
        setMsAddress(MSA);
        break;
      case 1101:
        setMsAddress(MSP);
        break;
    }
  }, [chainId])

  useEffect(() => {
    const addr = isAdmin ? ZERO : account
    axios.get(`https://tozexbridge.uc.r.appspot.com/transactions?address=${addr}`)
    .then(response =>{
      const res = response.data;
      res.sort((a, b) => new Date(a.date) - new Date(b.date));
      setTxs(res)
    })
    .catch(error => console.error('Error fetching data:', error));

  }, [isAdmin, account, progress])

  useEffect(() => {
    const initContract = async () => {
      const _bridge = new ethers.Contract(bridgeAddress, bridgeAbi.abi, library.getSigner(account));
      setBridge(_bridge);
      console.log(msAddress)
      const _multisig = new ethers.Contract(msAddress, multisigAbi.abi, library.getSigner(account));
      for (let i =  0 ; i < 2; i ++) {
        const admin = await _multisig.signers(i);
        if (admin.toLowerCase() === account.toLowerCase())
          setAdmin(true);
      }
      setMultisig(_multisig);
    };

    if(bridgeAddress && account && library) initContract();
  }, [library, account, bridgeAddress, msAddress]);

  const handleConfirmTx = async (tx) => {
    let targetChainId;
      // eslint-disable-next-line default-case
    switch(tx.direction[1]) {
      case "E":
        targetChainId = ethChainId;
        break;
      case "B":
        targetChainId = bscChainId;
        break;
      case "A":
        targetChainId = avaxChainId;
        break;
      case "P":
        targetChainId = pZkevmChainId;
        break;
    };
    if(chainId != targetChainId) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetChainId }],
      });
    } else {
      try {
        dispatch(setProgress(true));
        let _tx;
        if(!isAdmin)
          tx = await bridge.confirmTransaction(tx.id, {gasLimit: 500000});
        else
          tx = await multisig.confirmTransaction(tx.msId, {gasLimit: 500000}); 
        await _tx.wait();
      } catch(err) {
        console.log(`Error occured in confirmation, ${err}`);
      }
      dispatch(setProgress(false));
    }
  }

  const showNetwork = (direction, index) => {
    let network;
    // eslint-disable-next-line default-case
    switch(direction[index]) {
      case "E":
        network = "Ethereum";
        break;
      case "B":
        network = "Binance";
        break;
      case "A":
        network = "Avalanche";
        break;
      case "P":
        network = "Polygon Zkevm";
        break;
    };
    return network
  }

  const shortenAddress = (address) => {
    return address.slice(0, 6) + '...' + address.slice(-4);
  }

  const showTxHash = (tx) => {
    let targetChainId;
      // eslint-disable-next-line default-case
    switch(tx.direction[1]) {
      case "E":
        targetChainId = ethChainIdDec;
        break;
      case "B":
        targetChainId = bscChainIdDec;
        break;
      case "A":
        targetChainId = avaxChainIdDec;
        break;
      case "P":
        targetChainId = pZkevmChainIdDec;
        break;
    };
    const scanLink = CHAINS[targetChainId]['blockExplorerUrls'][0] + "/tx/" + tx.hash

    return (<a href={scanLink} target="_blank" >{shortenAddress(tx.hash)}</a>)
  }
  const confirmButton = (tx) => {
    if(isAdmin) {
      return (
        tx.msStatus === "Pending" ? 
        <td>
          <Button 
            shape="round" 
            type="primary" 
            style={styles.button} 
            onClick={() => handleConfirmTx(tx)}
          >
            Confirm
          </Button>
        </td> : 
        <td>
        <Button 
            shape="round" 
            type="primary" 
            style={styles.disabledbutton} 
            disabled
          >
            Confirm
          </Button>
        </td>
      )
    } else {
      return (
        tx.status === "Pending" ? 
        <td>
          <Button 
            shape="round" 
            type="primary" 
            style={styles.button} 
            onClick={() => handleConfirmTx(tx)}
          >
            Confirm
          </Button>
        </td> : 
        <td>
        <Button 
            shape="round" 
            type="primary" 
            style={styles.disabledbutton} 
            disabled
          >
            Confirm
          </Button>
        </td>
      )
    }
    
  }
  return (
    <Container>
      <div className="text-center my-3">
        <h1 className=" py-3 h1-responsive text-uppercase">My transactions</h1>
        <Alert variant="info">
          Before swapping again please confirm below your previous transaction
        </Alert>
      </div>

      <Row className="pb-4">
        <Table responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>From Chain</th>
              <th>To Chain</th>
              <th>TxID</th>
              <th>TxHash</th>
              <th>Wallet</th>
              <th>Token</th>
              <th>Amount</th>
              <th>Fee</th>
              <th>Date</th>
              <th>Status</th>
              <th>Multisig Status</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {
              txs.map((tx, index) => (
                <tr>
                  <td>{index + 1}</td>  
                  <td>{showNetwork(tx.direction, 0)}</td>
                  <td>{showNetwork(tx.direction, 1)}</td>
                  <td>{tx.id}</td>
                  <td>{showTxHash(tx)}</td>
                  <td>{shortenAddress(tx.address)}</td>
                  <td>TOZ</td>
                  <td>{tx.amount}</td>
                  <td>{tx.fee}</td>
                  <td>{getTime(tx.date)}</td>
                  <td>{tx.status}</td>
                  <td>{tx.msStatus}</td>
                  {
                    confirmButton(tx)
                  }
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default ResponsiveExample;
