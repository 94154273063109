import { createSlice } from "@reduxjs/toolkit";

export const bridgeSlice = createSlice({
    name: 'bridge',
    initialState: {
        progress: false
    },
    reducers: {
        setProgress: (state, action) => {
            state.progress = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProgress } = bridgeSlice.actions

export default bridgeSlice.reducer