import "../App.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CompanieCard from "./CompanieCard";
import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function SectionFinancingCompanies() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="Sect Sect-FC">
      <Container>
        <div className="text-center my-3">
          <svg height="100" viewBox="0 0 620.29 256.42">
            <g>
              <line
                x1="395.28"
                y1="259.84"
                x2="617.44"
                y2="-2.09"
                fill="none"
                stroke="#057ba8"
                stroke-width="5"
              />
            </g>
          </svg>
          <h1
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
            className="py-3 display-4 h1-responsive text-uppercase"
          >
            <span className="bold">Financing </span>Companies
          </h1>
          <svg height="100" viewBox="0 0 620.29 263.36">
            <g>
              <line
                x1="2.68"
                y1="262.13"
                x2="224.84"
                y2="0.2"
                fill="none"
                stroke="#057ba8"
                stroke-width="5"
              />
            </g>
          </svg>
        </div>
      </Container>
      <Container fluid className="my-5">
        <Row>
          <Col xs={12} lg={6} className="bg-primary text-white">
            <div className="m-5">
              <p className="lead pt-2">
                Get tokens at a fixed price and the guarantee that funded
                projects will deliver what they promises by an innovative
                funding governance model involving founders, Tozex and an
                insurance company.
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="text-center d-flex align-items-center justify-content-center"
          >
            <div className="mt-5 d-none d-md-block text-center">
              <Button
                variant="primary"
                size="lg"
                href="/home"
                className="mb-4 me-4 btn-shadow-primary"
              >
                <svg viewBox="0 0 48.08 40.9" width="20" className="me-3">
                  <use href="#icon-flecheUR"></use>
                </svg>
                VIEW ALL PROJECTS
              </Button>
              <Button
                variant="primary"
                size="lg"
                href="/home"
                className="mb-4 btn-shadow-primary"
              >
                <svg viewBox="0 0 48.08 40.9" width="20" className="me-3">
                  <use href="#icon-flecheUR"></use>
                </svg>
                GET FOUNDED
              </Button>
            </div>
            <div className="d-block d-md-none text-center">
              <Button
                variant="primary"
                href="/home"
                className="m-3 btn-shadow-primary"
              >
                <svg viewBox="0 0 48.08 40.9" width="17" className="me-2">
                  <use href="#icon-flecheUR"></use>
                </svg>
                VIEW ALL PROJECTS
              </Button>
              <Button
                variant="primary"
                href="/home"
                className="mx-3 btn-shadow-primary"
              >
                <svg viewBox="0 0 48.08 40.9" width="17" className="me-2">
                  <use href="#icon-flecheUR"></use>
                </svg>
                GET FOUNDED
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="py-5 flex-column d-flex justify-content-center">
          <Row>
            <Col
              xs={12}
              md={6}
              lg={5}
              className="flex-column d-flex mx-auto justify-content-center"
            >
              <CompanieCard />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={5}
              className="flex-column d-flex mx-auto justify-content-center"
            >
              <CompanieCard />
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <div className="pt-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 10">
            <g>
              <line
                x1="0"
                y1="0"
                x2="1024"
                y2="0"
                fill="none"
                stroke="#057ba8"
                height="90px"
                stroke-width="8"
              />
            </g>
          </svg>
        </div>
      </Container>
    </div>
  );
}

export default SectionFinancingCompanies;
