import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { setProgress } from '../../futures/bridge/bridgeSlice';
import ToggleTheme from '../ToggleTheme';
import Nav from '../Nav'
import HeaderBridge from './HeaderBridge'
import Footer from '../Footer'
import Cryptos from './BridgeBottom'
import BridgeNetwork from './BridgeNetwork'
import BridgeSwap from './BridgeSwap'
import TransactionsTable from './TransactionsTable'
import { NETWORKS } from "../../constants/networks";
import "../../index.css"
function Bridge() {
  const [amount, setAmount] = useState(0);
  const [networkFrom, setNetworkFrom] = useState(NETWORKS[0]);
  const [networkTo, setNetworkTo] = useState(NETWORKS[1]);

  const progress = useSelector((state) => state.bridge.progress)
  const dispatch = useDispatch()
  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  }

  const handleNetworkFromChange = (network) => {
    setNetworkFrom(network);
    // const toId = network.value === "ethereum" ? 1 : 0;
    // setNetworkTo(NETWORKS[toId]);
  };

  const handleNetworkToChange = (network) => {
    setNetworkTo(network);
    // const toId = network.value === "ethereum" ? 1 : 0;
    // setNetworkFrom(NETWORKS[toId]);
  };

  const handleSwapNetwork = () => {
    setNetworkTo(networkFrom);
    setNetworkFrom(networkTo);
  };




  return (
    <div>
      <ToggleTheme/>  
      <Nav/>
      <HeaderBridge amount={amount} setAmount={handleChangeAmount} networkFrom={networkFrom} networkTo={networkTo} setNetworkFrom={handleNetworkFromChange} setNetworkTo={handleNetworkToChange} swapNetwork={handleSwapNetwork} />
      <Cryptos/>
      {/* <BridgeNetwork controllerInfo={controllerInfo} amount={amount} setAmount={handleChangeAmount} networkFrom={networkFrom} networkTo={networkTo} setNetworkFrom={handleNetworkFromChange} setNetworkTo={handleNetworkToChange} /> */}
      {/* <BridgeSwap controllerInfo={controllerInfo} amount={amount} setAmount={handleChangeAmount} networkFrom={networkFrom} networkTo={networkTo} setNetworkFrom={handleNetworkFromChange} setNetworkTo={handleNetworkToChange} /> */}
      <TransactionsTable/>
      <Footer/>
      <Modal show={progress} className='modal-center'>
        <Modal.Body style={{ textAlign: 'center' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Bridge;
