import React, { Component } from "react";
import Slider from "react-slick";
import FFPB from "../media/FFPB.png";
import Hummingbot from "../media/Hummingbot.png";
import NGI from "../media/NGI.png";
import afnor from "../media/afnor.png";
import Blockpass from "../media/Blockpass.png";
import Button from "react-bootstrap/Button";
import fleche from "../media/FlecheD.svg";
import flecheL from "../media//FlecheG.svg";

export default class PreviousNextMethods extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
    return (
      <div>
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          <img
            className="px-2 px-md-3 px-lg-5 partner img-fluid"
            src={afnor}
            alt="partner"
          />
          <img
            className="px-2 px-md-3 px-lg-5 partner img-fluid"
            src={FFPB}
            alt="partner"
          />
          <img
            className="px-2 px-md-3 px-lg-5 partner img-fluid"
            src={Hummingbot}
            alt="partner"
          />
          <img
            className="px-2 px-md-3 px-lg-5 partner img-fluid"
            src={Blockpass}
            alt="partner"
          />
          <img
            className="px-2 px-md-3 px-lg-5 partner img-fluid"
            src={NGI}
            alt="partner"
          />
        </Slider>
        <div className="my-2 text-center">
          <Button className="text-white" variant="bluedark">
            <img
              src={flecheL}
              height="20px"
              alt="previous"
              onClick={this.previous}
            />
          </Button>
          <Button variant="bluedark">
            <img src={fleche} height="20px" alt="next" onClick={this.next} />
          </Button>
        </div>
      </div>
    );
  }
}
