import "../../App.scss";
import Dropdown from "react-bootstrap/Dropdown";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import { NETWORKS } from "../../constants/networks";

function CardBridge2(props) {
  const { network, setNetwork, amount } = props;

  return (
    <div className="d-flex flex-column">
      <div className="d-none">
        <svg xmlns="http://www.w3.org/2000/svg" className="hidden" id="BNB">
          <path
            fill="currentcolor"
            d="M13.24,30v9.87l8.38,4.92v5.78L8.33,42.76V27.12L13.21,30Zm0-9.87v5.78L8.35,23V17.25l4.89-2.89,4.91,2.89Zm11.91-2.88L30,14.36l4.9,2.89L30,20.13Z"
          />
          <path
            fill="currentcolor"
            d="M16.76,37.81V32l4.89,2.88v5.75Zm8.37,9L30,49.75l4.91-2.9v5.74L30,55.49l-4.88-2.9Zm16.75-29.6,4.88-2.89,4.91,2.89V23l-4.86,2.91V20.13l-4.88-2.88Zm4.88,22.62V30l4.87-2.9V42.76L38.35,50.55V44.79l8.37-4.92Z"
          />
          <polygon
            fill="currentcolor"
            points="43.31 37.8 38.43 40.67 38.43 34.92 43.31 32.04 43.31 37.8"
          />
          <path
            fill="currentcolor"
            d="M43.31,22.18V28l-8.38,4.92v9.88l-4.87,2.87-4.89-2.87V32.88L16.79,28V22.18l4.89-2.88,8.38,4.94,8.37-4.94,4.89,2.88ZM16.76,12.31,30,4.51l13.28,7.8-4.88,2.9-8.37-5-8.38,5-4.89-2.9Z"
          />
        </svg>
      </div>
      <div className="d-none">
        <svg xmlns="http://www.w3.org/2000/svg" className="hidden" id="Etherum">
          <path
            fill="currentcolor"
            d="M29.88,41.72,15.42,33.17,29.88,53.59,44.35,33.17Zm.23-35.31-14.48,24L30.11,39l14.47-8.55Z"
          />
        </svg>
      </div>
      <div className="d-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="hidden"
          id="Avalanche"
        >
          <path
            fill="currentcolor"
            d="M21.19,50.64H9.56A2.46,2.46,0,0,1,7.1,48.18,2.43,2.43,0,0,1,7.42,47L27.91,10.59a2.45,2.45,0,0,1,3.34-.93,2.38,2.38,0,0,1,.93.93l6.15,10.85a2.46,2.46,0,0,1,0,2.46l-15,25.49A2.48,2.48,0,0,1,21.19,50.64Z"
          />
          <path
            fill="currentcolor"
            d="M34.42,47l6.93-12.31a2.44,2.44,0,0,1,3.34-.94h0a2.47,2.47,0,0,1,.93.94L52.57,47a2.44,2.44,0,0,1-.91,3.35h0a2.32,2.32,0,0,1-1.21.33H36.58a2.47,2.47,0,0,1-2.47-2.46A2.36,2.36,0,0,1,34.42,47Z"
          />
        </svg>
      </div>
      <div className="d-none">
        <svg xmlns="http://www.w3.org/2000/svg" className="hidden" id="Polygon">
          <path
            fill="currentcolor"
            d="M39.16,22.17,20.86,32.73a2.27,2.27,0,0,1-2.23,0l-5.85-3.37a2.2,2.2,0,0,1-1.09-1.92V20.71a2.2,2.2,0,0,1,1.1-1.91l5.84-3.38a2.27,2.27,0,0,1,2.23,0L26.7,18.8a2.2,2.2,0,0,1,1.1,1.91V24a.57.57,0,0,0,.57.55.61.61,0,0,0,.26-.08l3.05-1.76a1.09,1.09,0,0,0,.55-1V18.13a2.21,2.21,0,0,0-1.1-1.91L20.84,10.29a2.25,2.25,0,0,0-2.22,0L8.35,16.22a2.24,2.24,0,0,0-1.11,1.93V30a2.24,2.24,0,0,0,1.11,1.91l10.27,5.93a2.25,2.25,0,0,0,2.22,0l18.3-10.58a2.19,2.19,0,0,1,2.21,0l5.86,3.37a2.23,2.23,0,0,1,1.1,1.92v6.75a2.2,2.2,0,0,1-1.1,1.91L41.38,44.6a2.2,2.2,0,0,1-2.22,0l-5.84-3.38a2.19,2.19,0,0,1-1.11-1.91V36.06a.55.55,0,0,0-.55-.55.53.53,0,0,0-.27.08l-3.05,1.75a1.08,1.08,0,0,0-.55,1v3.57a2.21,2.21,0,0,0,1.1,1.91l10.27,5.93a2.25,2.25,0,0,0,2.22,0l10.26-5.93a2.22,2.22,0,0,0,1.12-1.91V30a2.24,2.24,0,0,0-1.12-1.93L41.38,22.17A2.25,2.25,0,0,0,39.16,22.17Z"
          />
        </svg>
      </div>

      <Card className="m-2">
        <Card.Header className="mt-2">
          <Stack direction="horizontal" gap={2}>
            <div className="">
              <small>To</small>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="dark" id="">
                <svg viewBox="0 0 60 60" height="25" className="ms-n2 me-2">
                  <use href={network.svg}></use>
                </svg>
                {network.label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {NETWORKS.map(net => (
                  <Dropdown.Item key={net.value} onClick={() => setNetwork(net)}>
                    <svg className="ms-n2 me-2" viewBox="0 0 60 60" height="25">
                      <use href={net.svg}></use>
                    </svg>
                    {net.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Stack>
        </Card.Header>
        <Card.Body className="text-start">
          <h2 className="h2-responsive">
            {amount}<span className="bold"> TOZ</span>
          </h2>
        </Card.Body>
      </Card>
      <Card className="m-2">
        <Card.Footer>
          <div className="text-muted">
            <small>
              Estimated Time is 10-30 min or up to 12 hours if larger than
              50,000 TOZ
            </small>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default CardBridge2;
