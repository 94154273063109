import React from 'react'
import Button from 'react-bootstrap/Button'
import {useEffect, useState} from "react"
import './App.scss'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Header3 from './components/Header3'
import Nav from './components/Nav'
import SectionFinancingCompanies from './components/SectionFinancingCompanies'
import SectionCollectingNFT from './components/SectionCollectingNFT'
import SectionTozex from './components/SectionTozex'
import SectionMedium from './components/SectionMedium'
import SectionQuestions from './components/SectionQuestions'
import Cryptos from './components/PartCryptos'
import Numbers from './components/SectionNumbers'
import Footer from './components/Footer'

function App() {
const [theme, setTheme] = useState(
localStorage.getItem('theme') || 'theme-dark'
);

const toggleTheme = () => {
if (theme === 'theme-light') {
setTheme('theme-dark');
} else {
setTheme('theme-light');
}
};
useEffect(() => {
localStorage.setItem('theme', theme);
document.body.className = theme;
}, [theme]);


  return (
    <body>
      <div className={`App ${theme}`}>
      <Button className="rounded-circle btn-switch" onClick={toggleTheme}></Button>
      <Nav/>
      <Header3/>
      <Cryptos/>
      <Numbers/>
      <SectionFinancingCompanies/>
      <SectionCollectingNFT/>
      <SectionTozex/>
      <SectionMedium/>
      <SectionQuestions/>
      <Footer/>
    </div>
    </body>
  );
}

export default App;
