import "../../App.scss";
import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import {ethers, BigNumber} from "ethers";
import axios from 'axios';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import CardBridge1 from "./cardBridge1";
import CardBridge2 from "./CardBridge2";
import {BA, TKNB, TKNE} from "../../constants/addresses";
import tokenAbi from "../../abis/Token.json";
import { setProgress } from "../../futures/bridge/bridgeSlice";
import { ethChainIdDec } from "../../utils/web3";

function BridgeSwap(props) {
  const { account, library, chainId } = useWeb3React();
  const dispatch = useDispatch()
  const [token, setToken] = useState();
  const [tokenAddress, setTokenAddress] = useState(null);
  const [bridgeAddress, setBridgeAddress] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (chainId === ethChainIdDec) {
      setTokenAddress(TKNE);
      setBridgeAddress(BA);
    } else {
      setTokenAddress(TKNB);
      setBridgeAddress(BA);
    }
  }, [chainId])

  useEffect(() => {
    const initContract = async () => {
      console.log({library, account, tokenAddress})
      const _token = new ethers.Contract(tokenAddress, tokenAbi.abi, library.getSigner(account));
      setToken(_token);
    };

    if(tokenAddress && account && library) initContract();
  }, [library, account, tokenAddress]);



  const handleSwap = async () => {
    try {
      dispatch(setProgress(true));
      const direction = chainId === ethChainIdDec ? "EB" : "BE";

      // Check if the user has already approved the token for the transfer amount
      const amount = BigNumber.from(props.amount).mul(BigNumber.from('10').pow('18'));

      const allowance = await token.allowance(account, bridgeAddress);
      console.log(allowance.toString());
      
      if (allowance.lt(amount)) {
        // Approve the token
        const tx = await token.approve(bridgeAddress, amount);
        await tx.wait();
      }

      await axios.get(`https://tozexbridge.uc.r.appspot.com/process?direction=${direction}&address=${account}&token_index=0`);
      dispatch(setProgress(false));
      
      // Show a success message
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <header className="bg-bridge Sect Sect-Header flex-column text-center d-flex justify-content-center">
        <Container>
          <div className="text-center my-3">
            <svg height="100" viewBox="0 0 620.29 256.42">
              <g>
                <line
                  x1="395.28"
                  y1="259.84"
                  x2="617.44"
                  y2="-2.09"
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="5"
                />
              </g>
            </svg>
            <h1 className="text-white py-3 display-4 h1-responsive text-uppercase">
              <span className="bold">Bridge</span>
            </h1>
          </div>

          <Row className="pb-4">
            <Col xs={12} lg={3}></Col>
            <Col xs={12} lg={5} className="mx-auto">
              <CardBridge1 
                network={props.networkFrom} 
                setNetwork={props.setNetworkFrom}
                {...props}
              />
              <div className="my-4">
                <Button variant="bridge">
                  <svg
                    className="display-block ms-n2"
                    viewBox="0 0 97.25 60"
                    height="25"
                  >
                    <path
                      fill="currentcolor"
                      d="M50.63,26.19l3.64,3.39L68.34,14.47,68.19,51.7l4.88,0,0-37.23L87,29.58l3.6-3.34-20-21.51Z"
                    />
                    <path
                      fill="currentcolor"
                      d="M48.65,35,45,31.65,30.93,46.75l.15-37.23H26.2V46.74L12.26,31.65,8.67,35l20,21.5Z"
                    />
                  </svg>
                </Button>
              </div>

              <CardBridge2 
                network={props.networkTo} 
                setNetwork={props.setNetworkTo} 
                {...props}
              />

              <div className="pt-3 flex-row text-center">
                <Button variant="dark" className="btn-dark-bridge mb-4" onClick={handleSwap}>
                  Swap
                </Button>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <Card className="m-2">
                <Card.Body className="text-start">
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={account}
                    />
                    <div className="text-nowrap text-muted ms-auto">
                      <small>Ethereum Mainnet</small>
                    </div>
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={2}>
                    <div>Copy address</div>
                    <div className="ms-auto">
                      <Button size="sm" variant="light">
                        Disconnect
                      </Button>
                    </div>
                  </Stack>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        
      </header>
    </div>
  );
}
export default BridgeSwap;
