import "../App.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Numbers() {
  return (
    <div>
      <Container fluid className="bg-bluedark text-white">
        <Container>
          <Row className="py-4 text-center d-flex flex-row align-items-start">
            <Col xs={6} md={4} lg={3} className="text-center mx-auto">
              <div className="">
                <h1 className="h1-responsive display-4">
                  $35.74<span className="fs-4"> M</span>
                </h1>
                <p>Investment generated</p>
              </div>
            </Col>
            <Col xs={6} md={4} lg={3} className="text-center mx-auto">
              <div>
                <h1 className="display-4">
                  $450<span className="fs-4"> M</span>
                </h1>
                <p>Global Crypto Market Cap</p>
              </div>
            </Col>
            <Col xs={6} md={4} lg={3} className="text-center mx-auto">
              <div>
                <h1 className="display-4">
                  40000<span className="fs-4"> +</span>
                </h1>
                <p>Total Investors Registered</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Numbers;
