import "../App.scss";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Nav from "react-bootstrap/Nav";
import axios from "axios";

function Footer() {
  const [email, setEmail] = useState("");
  const handleSubscribe = (event) => {
    event.preventDefault();
    axios.post(`https://tozex.io/api/newsletter`, {email: email})
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  return (
    <footer className="waterproof">
      <div className="d-block divise"></div>
      <Container className="pt-5">
        <div className="text-center title">
          <h1 className="h1-responsive text-uppercase">
            SUBSCRIBE TO <span className="bold">OUR MAILING NEWS</span>
          </h1>
        </div>
        <Form onSubmit={handleSubscribe}>
          <Row className="my-2 py-5 flex-row d-flex justify-content-center">
            <Col xs={12} md={8} lg={6}>
              <Stack className="subscribe" direction="horizontal" gap={3}>
                <Form.Control
                  type="email"
                  className="me-auto"
                  placeholder="youremail@domain.com"
                  value={email}
                  onChange={e=>setEmail(e.target.value)}
                />
                <Button className="moveR" variant="light" type="submit">
                  <svg viewBox="0 0 48.08 40.9" height="22">
                    <polygon
                      fill="currentcolor"
                      points="26.09 0 22.68 3.66 38.04 17.95 0 17.95 0 22.94 38.05 22.95 22.68 37.24 26.09 40.9 48.08 20.45 26.09 0"
                    />
                  </svg>
                </Button>
              </Stack>
            </Col>
          </Row>
        </Form>
        <div>
          <Row className="my-3 justify-content-center">
            <Col xs={6} md={4}>
              <Nav className="flex-column navfoot">
                <Nav.Link href="#action1">Company</Nav.Link>
                <Nav.Link href="#action1">About </Nav.Link>
                <Nav.Link href="#action1">Carreers</Nav.Link>
                <Nav.Link href="#action1">Contact Us</Nav.Link>
              </Nav>
            </Col>
            <Col xs={6} md={4}>
              <Nav className="flex-column navfoot">
                <Nav.Link href="#action1">Ressources </Nav.Link>
                <Nav.Link href="#action1">Documentation</Nav.Link>
                <Nav.Link href="#action1">Help center </Nav.Link>
                <Nav.Link href="#action1">FAQ </Nav.Link>
              </Nav>
            </Col>
            <Col xs={6} md={4}>
              <Nav className="flex-column navfoot">
                <Nav.Link href="#action1">Conditions</Nav.Link>
                <Nav.Link href="#action1">Privacy Policy</Nav.Link>
                <Nav.Link href="#action1">Term of service</Nav.Link>
                <Nav.Link href="#action1">KYC & AML</Nav.Link>
              </Nav>
            </Col>
          </Row>
        </div>
      </Container>
      <div>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={8} lg={6} className="mx-auto d-block">
            <p className="text-muted text-center">©2023 Tozex Inc</p>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
